<script setup lang="ts">
interface Props {
  width?: string;
  height?: string;
  color?: string;
  margin?: string;
}

const props = withDefaults(defineProps<Props>(), {
  width: '100%',
  height: '0.5px',
  color: '#b8becc',
  margin: '0.7rem 0',
});
const style = {
  width: props.width,
  height: props.height,
  backgroundColor: props.color,
  margin: props.margin,
  zIndex: 0,
};
</script>

<template>
  <div :style="style" />
</template>
