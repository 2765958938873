import { useAxios } from '@vueuse/integrations/useAxios';
import axiosInstance from '@/api/api.instance';
import type { AxiosResponse } from 'axios';
import type { UiRevisionResponse } from '@/interfaces/portal.interface';

export const getUiRevision = () =>
  useAxios<AxiosResponse<UiRevisionResponse>>(
    '/portal/ui-revision',
    axiosInstance,
  );
