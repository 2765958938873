<script setup lang="ts">
import { computed } from 'vue';
import Button from 'primevue/button';
interface Props {
  variant?:
    | 'primary'
    | 'secondary'
    | 'clear-rounded'
    | 'outlined'
    | 'white'
    | 'black-outlined';
  size?: 'sm' | 'md' | 'lg';
  dataTestId?: string;
  loading?: boolean;
}

const props = defineProps<Props>();

const sizeClass = computed(() => {
  switch (props.size) {
    case 'sm':
      return 'p-button-sm';
    case 'lg':
      return 'p-button-lg';
    default:
      return 'p-button';
  }
});
</script>

<template>
  <Button
    :class="['button', variant && `button--${variant}`, sizeClass]"
    :data-testid="dataTestId"
  >
    <slot />
    <ProgressSpinner v-if="loading" />
  </Button>
</template>

<style scoped lang="scss">
.button {
  border: none;
  white-space: nowrap;
  &:hover {
    color: initial;
  }
  &--primary {
    border: 1px solid var(--color-green-200) !important;
    border-radius: 8px !important;
    color: var(--color-primary-gunmetal) !important;
    background-color: var(--color-green-200) !important;
    &:hover {
      background-color: var(--color-primary-tevora-green) !important;
    }
  }
  &--outlined {
    $border-width: 2px;
    @extend .p-button-outlined;
    @extend .p-button-secondary;
    background: transparent;
    border: $border-width solid var(--color-gray-200);
    &:hover {
      background-color: var(--color-gray-200);
      border: $border-width solid var(--color-gray-200);
    }
  }
  &--white {
    background-color: var(--color-base-white);
    color: var(--color-base-black);
    border: 1px solid var(--color-base-white);
    &:hover {
      background-color: var(--color-gray-200);
      border: 1px solid var(--color-gray-200);
    }
  }
  &--black-outlined {
    background-color: var(--color-base-white);
    color: var(--color-base-black);
    border: 1px solid var(--color-base-black);
    &:hover {
      background-color: var(--color-gray-200);
      border: 1px solid var(--color-gray-200);
    }
  }
  &--clear-rounded {
    border-radius: 17px !important;
    color: var(--color-secondary-black-coral) !important;
    border-color: var(--color-secondary-black-coral) !important;
  }
  .p-progress-spinner {
    margin-left: 0.7rem;
    width: 20px;
    height: 20px;
  }
}

[data-theme='dark'] {
  .button {
    &:hover {
      color: initial;
    }
    &--clear-rounded {
      border-radius: 17px !important;
      color: var(--color-base-white) !important;
      border-color: var(--color-base-white) !important;
    }
  }
}

@keyframes p-progress-spinner-color {
  100%,
  0% {
    stroke: var(--color-primary-forest-green);
  }
  40% {
    stroke: var(--color-primary-forest-green);
  }
  66% {
    stroke: var(--color-primary-forest-green);
  }
  80%,
  90% {
    stroke: var(--color-primary-forest-green);
  }
}
</style>
