<script setup lang="ts">
import { computed } from 'vue';

interface Props {
  label: string;
  modelValue?: string | undefined | null;
  value?: string;
  vuelidate?: any;
  name?: string;
  readonly?: boolean;
}

const props = defineProps<Props>();
const emit = defineEmits(['update:modelValue']);

const v$ = computed(
  () => (props.vuelidate && props.vuelidate[props.name || '']) || {},
);

const editableContent = computed({
  get() {
    return props.modelValue || '';
  },
  set(value: string | undefined) {
    emit('update:modelValue', value || '');
  },
});
</script>

<template>
  <div class="field">
    <div class="field__title">{{ label }}</div>
    <InputText
      v-model="editableContent"
      :readonly="readonly"
      class="field__input"
      @blur="v$.$touch && v$.$touch()"
    />
    <small v-if="v$.$error" class="text-pink-500">
      {{ v$.$errors[0].$message }}
    </small>
  </div>
</template>

<style scoped lang="scss">
.field {
  display: flex;
  flex-direction: column;
  &__input {
    border-radius: 12px;
    width: 100%;
    padding: 0.8rem 1.4rem;
  }
  &__title {
    font-weight: bold;
    font-size: 0.9rem;
    padding-left: 0;
    margin-bottom: 4px;
  }
}
</style>
