import type { AxiosError, AxiosInstance } from 'axios';
import { ToastSeverity } from 'primevue/api';
import { useNotificationsStore } from '@/stores/notifications.store';
import errorMessages from '../errors/error-messages';
import type ErrorCodes from '../errors/error-codes';
import errorSummaries from '@/api/errors/error-summaries';
import StatusCodes from '../enums/status-codes.enum';
import { remove } from '@/utils/local-storage.utils';

interface ErrorResponseData {
  error?: ErrorCodes;
}

type AxiosApiError = AxiosError<ErrorResponseData>;

const getErrorCode = (exception?: AxiosApiError) => {
  return exception?.response?.data?.error;
};

const getErrorSummary = (responseStatus?: number) => {
  return responseStatus ? errorSummaries[responseStatus] : undefined;
};

const setupResponseInterceptors = (instance: AxiosInstance) => {
  instance.interceptors.response.use(
    (response) => {
      return response;
    },
    (exception: AxiosApiError) => {
      const { showToastNotification } = useNotificationsStore();

      const responseStatus = exception.response?.status;
      const errorCode = getErrorCode(exception);
      const errorMessage = errorCode ? errorMessages[errorCode] : undefined;
      const errorSummary = getErrorSummary(responseStatus);

      if (responseStatus === StatusCodes.UNAUTHORIZED) {
        const { pathname, search, hash } = window.location;
        window.localStorage.setItem('toURL', pathname + search + hash);
        window.location.href = `${
          import.meta.env.VITE_API_URL
        }/auth/saml/login`;
        return Promise.reject(exception);
      }

      if (errorMessage && errorSummary && errorCode) {
        showToastNotification({
          severity: ToastSeverity.ERROR,
          summary: errorSummary,
          detail: `${errorMessage}\nError code: ${errorCode}`,
        });
      } else {
        const requestId = exception.response?.headers['x-request-id'];
        showToastNotification({
          severity: ToastSeverity.ERROR,
          summary: 'Api Error',
          detail: `Oops, something went wrong\n\n\nRequest Id: ${requestId}`,
        });
      }

      return Promise.reject(exception);
    },
  );
};

export default setupResponseInterceptors;
