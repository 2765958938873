<script setup lang="ts">
import { useDebounceFn } from '@vueuse/core';
import { ref, watch } from 'vue';
interface Props {
  placeholder?: string;
  debounce?: number;
}
const props = withDefaults(defineProps<Props>(), {
  placeholder: 'Search',
  debounce: 500,
});
const emit = defineEmits(['query']);
const searchQuery = ref('');

const emitChanges = useDebounceFn(() => {
  emit('query', searchQuery.value);
}, props.debounce);

watch(searchQuery, emitChanges);
</script>

<template>
  <span class="search">
    <FontIcon :icon="['far', 'magnifying-glass']" size="lg" />
    <InputText
      v-model="searchQuery"
      class="search__input"
      type="text"
      :placeholder="placeholder"
    />
  </span>
</template>

<style scoped lang="scss">
.search {
  border: 2px solid var(--color-secondary-lavender-gray);
  padding: 0.5rem;
  border-radius: 10px;
  height: 40px;
  display: flex;
  align-items: center;
  min-width: 20rem;
  i {
    color: var(--color-font);
    font-weight: 700;
    font-size: 18px;
  }
  &__input {
    width: 20rem;
    padding-left: 1rem;
    background-color: transparent;
    border: 0;
    color: var(--color-secondary-black-coral);
    outline-style: none;
    box-shadow: none;
    border-color: transparent;
    @include media(md) {
      flex: 1 1 auto;
    }
  }
}
</style>
