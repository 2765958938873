<script setup lang="ts">
import { read, save } from '@/utils/local-storage.utils';
import { ref, watch } from 'vue';
import { getUiRevision } from '@/api/portal.api';
import { computed } from '@vue/reactivity';

const currentRevision = ref<number>();

const { data } = getUiRevision();
const latestRevision = computed(() => data.value?.data.revision);

const newVersionIsAvailable = computed(
  () =>
    latestRevision.value && (currentRevision.value || 0) < latestRevision.value,
);

watch(latestRevision, (value) => {
  currentRevision.value = read('ui-revision-top');

  if (value && !currentRevision.value) {
    currentRevision.value = value;
    save('ui-revision-top', value);
  }
});

const close = () => {
  currentRevision.value = latestRevision.value;
  save('ui-revision-top', latestRevision.value);
};

const refresh = () => {
  save('ui-revision-top', latestRevision.value);
  window.location.reload();
};
</script>

<template>
  <div v-if="newVersionIsAvailable" class="notification">
    <div class="notification__close">
      <FontIcon :icon="['far', 'circle-x']" @click="close()" />
    </div>
    <p>A new and improved version of the application is now available.</p>
    <p>
      <a
        class="notification__refresh"
        href="javascript:void(0)"
        @click="refresh()"
      >
        <FontIcon :icon="['fas', 'arrows-rotate']" />
        Refresh Now
      </a>
    </p>
  </div>
</template>

<style scoped lang="scss">
.notification {
  position: fixed;
  z-index: 1;
  right: 5%;
  bottom: 10%;
  min-width: 250px;

  border-radius: 4px;
  background-color: var(--color-primary-tevora-green);

  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px 20px;
  text-align: center;

  &__close {
    display: flex;
    justify-content: flex-end;
  }

  &__refresh {
    color: inherit;
    display: flex;
    gap: 5px;
    justify-content: center;
    align-items: center;
  }
}
</style>
