<script setup lang="ts">
import { computed } from 'vue';

interface Props {
  visible: boolean;
  title?: string;
  deleteText?: string;
  minWidth?: string;
  maxWidth?: string;
  submitText?: string;
  isDanger?: boolean;
  footer?: boolean;
  footerText?: string;
  closable?: boolean;
  cancelBtn?: boolean;
  isLoading?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  deleteText: undefined,
  title: '',
  minWidth: '420px',
  maxWidth: 'auto',
  submitText: 'Save',
  isDanger: false,
  footer: true,
  footerText: undefined,
  closable: true,
  cancelBtn: true,
  isLoading: false,
});

const emit = defineEmits([
  'update:visible',
  'update',
  'remove',
  'submit',
  'cancel',
]);
const modalVisible = computed({
  get() {
    return props.visible;
  },
  set(value: boolean) {
    emit('update:visible', value);
  },
});

const handleCancel = () => {
  modalVisible.value = false;
  emit('cancel');
};
</script>

<template>
  <Dialog
    v-model:visible="modalVisible"
    :dismissable-mask="false"
    :close-on-escape="false"
    :modal="true"
    :closable="closable"
    :style="{ minWidth, maxWidth }"
    class="top-dialog"
    :draggable="false"
  >
    <template #header>
      <slot name="header">
        <h3 :class="[isDanger && 'title-danger']">
          {{ title }}
        </h3>
      </slot>
    </template>

    <slot />

    <template v-if="footer" #footer>
      <slot name="footer">
        <div class="top-dialog__actions align-items-end">
          <span v-if="footerText" class="footer-text">{{ footerText }}</span>
          <div class="top-dialog__main-actions justify-content-end">
            <TevoraButton
              v-if="cancelBtn"
              class="modal-btn btn__cancel"
              :disabled="isLoading"
              @click="handleCancel"
            >
              Cancel
            </TevoraButton>
            <TevoraButton
              :class="['modal-btn', isDanger && 'danger-btn']"
              variant="primary"
              :disabled="isLoading"
              @click="emit('submit')"
            >
              {{ submitText }}
            </TevoraButton>
          </div>
          <span v-if="deleteText" class="btn__delete" @click="emit('remove')">
            {{ deleteText }}
          </span>
        </div>
      </slot>
    </template>
  </Dialog>
</template>

<style lang="scss" scoped>
.top-dialog {
  &__actions {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    align-items: flex-start;
    .btn__delete {
      color: var(--color-red-error);
      padding-top: 1rem;
      text-decoration: underline;
      cursor: pointer;
      &:hover {
        opacity: 0.8;
      }
    }
  }

  .title-danger {
    color: var(--color-error-red) !important;
    font-size: 32px;
    margin: 1rem auto;
  }
  .footer-text {
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 1rem;
    color: var(--color-base-black);
  }
  &__main-actions {
    display: flex;
    font-size: 12px;
    width: 100%;
    justify-content: flex-start;
    .modal-btn {
      font-weight: 500;
      font-size: 16px;
    }
    .danger-btn {
      background-color: var(--color-secondary-orange-bright) !important;
      border-color: var(--color-secondary-orange-bright) !important;
    }
    .btn__cancel {
      background: var(--color-secondary-ghost-white);

      color: var(--font-color);
      padding: 0.8rem 3rem;
      &:hover {
        background: var(--color-secondary-gainsboro);
      }
    }
  }
}
[data-theme='dark'] {
  .top-dialog {
    .footer-text {
      color: var(--color-base-white);
    }
  }
}
</style>
