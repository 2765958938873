import { SystemSettingsStatus } from '@/enums/system-settings.enum';
import type { SystemSetting } from '@/interfaces/system-settings.interface';
import { defineStore } from 'pinia';

interface PortalState {
  maintenanceMessage: SystemSetting | null;
  messageClosed: boolean;
  noDataAccessMessage: SystemSetting | null;
  noDataAccessMessageClosed: boolean;
}

export const initialPortalState: PortalState = {
  maintenanceMessage: null,
  noDataAccessMessage: null,
  messageClosed: false,
  noDataAccessMessageClosed: false,
};

export const usePortalStore = defineStore('portal', {
  state: (): PortalState => initialPortalState,
  actions: {
    setPortalState(state: PortalState) {
      this.$state = state;
    },
  },
  getters: {
    isMessageBannerOpen(state: PortalState) {
      return (
        !!state.maintenanceMessage?.value &&
        state.maintenanceMessage?.status === SystemSettingsStatus.Active &&
        !state.messageClosed
      );
    },
    isNoDataAccessMessageOpen(state: PortalState) {
      return (
        !!state.noDataAccessMessage?.value && !state.noDataAccessMessageClosed
      );
    },
  },
});
