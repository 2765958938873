<script setup lang="ts">
import { ref } from 'vue';
import { capitalizeFirstLetter } from '@/utils';
import type { DataTableRowEditCancelEvent } from 'primevue/datatable';
/* eslint-disable @typescript-eslint/no-explicit-any */

interface TableColumn {
  field: string;
  header?: string;
  sortable?: boolean;
  style?: Partial<CSSStyleDeclaration>;
  transform?: (value: any) => string;
  component?: string;
  componentProps?: { [key: string]: any };
  cellBackground?: boolean;
}

interface Props {
  data: any[] | undefined;
  columns: TableColumn[];
  sortField?: string;
  expandable?: boolean;
  expandableField?: string;
  expandableComponent?: any;
}

defineProps<Props>();
const expandedRows = ref([]);

const scrollIntoElement = (event: DataTableRowEditCancelEvent) => {
  const row = event.originalEvent.target;
  (row as any).scrollIntoView({
    block: 'center',
    behavior: 'smooth',
  });
};

const getObjectValue = ({ data }: any, value: any) => {
  if (value?.indexOf('.') !== -1) {
    const properties = value.split('.');
    let finalValue = { ...data };
    for (let i = 0; i < properties.length; i++) {
      if (finalValue[properties[i]]) {
        finalValue = finalValue[properties[i]];
      }
    }
    return finalValue;
  }
  return data[value];
};
</script>

<template>
  <DataTable
    v-model:expandedRows="expandedRows"
    :value="data"
    :sort-field="sortField"
    :sort-order="-1"
    show-gridlines
    responsive-layout="scroll"
    resizable-columns
    column-resize-mode="expand"
    @row-expand="scrollIntoElement"
    @row-collapse="null"
  >
    <Column v-if="expandable" :expander="true" header-style="width: 3rem" />
    <Column
      v-for="(column, index) in columns"
      :key="index"
      :field="column.field"
      :header="
        column.header ? column.header : capitalizeFirstLetter(column.field)
      "
      :style="column?.style"
      :sortable="column?.sortable"
    >
      <template v-if="column.component" #body="slotProps">
        <component
          :is="column.component"
          :data="column.componentProps"
          :class="[
            column.cellBackground &&
              `cell-background cell-background__${
                slotProps.data[column.field]
              }`,
            column.component === 'Chip' &&
              `status__${slotProps.data[column.field]} table-chip`,
          ]"
        >
          {{
            column.transform
              ? column.transform(getObjectValue(slotProps, column.field))
              : getObjectValue(slotProps, column.field)
          }}
        </component>
      </template>
      <template v-else #body="slotProps">
        <div class="flex">
          <span class="flex mr-4">
            {{
              column.transform
                ? column.transform(getObjectValue(slotProps, column.field))
                : getObjectValue(slotProps, column.field)
            }}
          </span>
          <FontIcon
            v-if="column.rightIcon"
            class="right-icon flex"
            :icon="column.rightIcon"
            size="lg"
          />
        </div>
      </template>
    </Column>
    <template v-if="expandable" #expansion="slotProps">
      <component
        :is="expandableComponent"
        v-if="expandableComponent"
        v-bind="slotProps.data"
      />
    </template>
  </DataTable>
</template>

<style scoped lang="scss">
:deep(.p-datatable-wrapper) {
  border-radius: 12px;
}

:deep(.right-icon) {
  margin-left: auto !important;
}
</style>
