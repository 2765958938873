import type { App } from 'vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import {
  faCircleUser,
  faCircleQuestion,
  faCirclePlus,
  faMagnifyingGlass,
  faCircleX,
  faFileImport,
  faSliders,
} from '@fortawesome/pro-regular-svg-icons';
import {
  faBell,
  faBars,
  faTimes,
  faMoon,
  faSun,
  faGear,
  faSearch,
  faPenToSquare,
  faFile,
  faChevronLeft,
  faPaperclip,
  faMemoCircleInfo,
  faRightFromBracket,
  faChevronDown,
  faBuilding,
  faCheck,
  faChevronRight,
  faChevronUp,
  faInfoCircle,
  faEllipsisVertical,
  faExclamationTriangle,
  faCalendar,
  faClock,
  faCheckCircle,
  faArrowsRotate,
  faSunBright,
} from '@fortawesome/pro-solid-svg-icons';

library.add(
  faCirclePlus,
  faBell,
  faCircleQuestion,
  faBars,
  faTimes,
  faRightFromBracket,
  faMoon,
  faSun,
  faGear,
  faSearch,
  faExclamationTriangle,
  faFile,
  faChevronLeft,
  faPaperclip,
  faMemoCircleInfo,
  faPenToSquare,
  faCircleUser,
  faChevronDown,
  faBuilding,
  faMagnifyingGlass,
  faCheck,
  faCircleX,
  faChevronRight,
  faChevronUp,
  faInfoCircle,
  faCalendar,
  faClock,
  faCheckCircle,
  faEllipsisVertical,
  faFileImport,
  faSliders,
  faArrowsRotate,
  faSunBright,
);

const defineIconsPlugin = (vueApp: App) => {
  vueApp.component('FontIcon', FontAwesomeIcon);
  // dynamic load of font-awesome library
  import('/node_modules/@fortawesome/fontawesome-pro/css/all.css');
};

export default defineIconsPlugin;
