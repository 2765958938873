/* eslint-disable @typescript-eslint/no-explicit-any */

export const save = (key: string, value: any) => {
  localStorage.setItem(key, JSON.stringify(value));
};

export const remove = (key: string) => {
  localStorage.removeItem(key);
};

export const read = (key: string) => {
  const value = localStorage.getItem(key);
  if (value) {
    return JSON.parse(value);
  }
  return undefined;
};
