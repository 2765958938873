import 'primevue/resources/themes/saga-green/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import '../node_modules/tevora-ui/dist/style.css';

import { createApp } from 'vue';
import { createPinia } from 'pinia';
import piniaPersist from 'pinia-plugin-persist';

import App from './App.vue';
import router from './router';

import settingsModule from './modules/settings';
import reportsModule from './modules/reports';
import requestsModule from './modules/requests';
import projectsModudule from './modules/projects';

import defineIconsPlugin from './plugins/icons.plugin';
import piniaCustomProperties from './plugins/pinia.plugin';
import defineComponentsPlugin from './plugins/components.plugin';
import defineAnimationsPlugin from './plugins/animations.plugin';

import { registerModules } from './register-modules';

registerModules({
  reports: reportsModule,
  requests: requestsModule,
  settings: settingsModule,
  projects: projectsModudule,
});

const app = createApp(App);
const pinia = createPinia();

defineComponentsPlugin(app);
defineAnimationsPlugin(app);
defineIconsPlugin(app);

pinia.use(piniaCustomProperties);
pinia.use(piniaPersist);

app.use(pinia);
app.use(router);

app.mount('#app');
