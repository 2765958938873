import type { App } from 'vue';

import PrimeVue from 'primevue/config';
import BadgeDirective from 'primevue/badgedirective';
import ConfirmationService from 'primevue/confirmationservice';
import Ripple from 'primevue/ripple';
import ToastService from 'primevue/toastservice';
import Tooltip from 'primevue/tooltip';
import StyleClass from 'primevue/styleclass';

import Tag from 'primevue/tag';
import Toast from 'primevue/toast';
import Avatar from 'primevue/avatar';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import ColumnGroup from 'primevue/columngroup';
import Row from 'primevue/row';
import Card from 'primevue/card';
import Badge from 'primevue/badge';
import Chip from 'primevue/chip';
import Divider from 'primevue/divider';
import Sidebar from 'primevue/sidebar';
import Skeleton from 'primevue/skeleton';
import InputText from 'primevue/inputtext';
import Checkbox from 'primevue/checkbox';
import Dropdown from 'primevue/dropdown';
import RadioButton from 'primevue/radiobutton';
import MultiSelect from 'primevue/multiselect';
import AutoComplete from 'primevue/autocomplete';
import Dialog from 'primevue/dialog';
import ProgressSpinner from 'primevue/progressspinner';
import Textarea from 'primevue/textarea';
import Calendar from 'primevue/calendar';

import TOPButton from '@/components/TOPButton.vue';
import TOPTable from '@/components/TOPTable.vue';
import TOPDivider from '@/components/TOPDivider.vue';
import TOPSearchInput from '@/components/TOPSearchInput.vue';
import TOPCard from '@/components/TOPCard.vue';
import TOPDialog from '@/components/TOPDialog.vue';
import TOPBackButton from '@/components/TOPBackButton.vue';
import TOPAutocomplete from '@/components/TOPAutocomplete.vue';
import TOPInputText from '@/components/TOPInputText.vue';
import TOPSuccessIcon from '@/components/TOPSuccessIcon.vue';
import TOPAttachedFilesList from '@/components/TOPAttachedFilesList.vue';
import TOPDateTimeInput from '@/components/TOPDateTimeInput.vue';
import TableLink from '@/components/table/TableLink.vue';
import {
  TevoraSearchInput,
  TevoraInputText,
  TevoraChip,
  TevoraTag,
  TevoraCheckbox,
  TevoraCollapsiblePanel,
  TevoraRadioButton,
  TevoraButton,
  TevoraModalInputText,
  TevoraDropDown,
  TevoraTable,
  TevoraIconSwitch,
  TevoraMenu,
} from 'tevora-ui';

const defineComponentsPlugin = (vueApp: App) => {
  vueApp.use(PrimeVue, { ripple: false });

  vueApp.use(ConfirmationService);
  vueApp.use(ToastService);

  vueApp.directive('ripple', Ripple);
  vueApp.directive('tooltip', Tooltip);
  vueApp.directive('badge', BadgeDirective);
  vueApp.directive('styleclass', StyleClass);
  vueApp.component('Toast', Toast);
  vueApp.component('Tag', Tag);
  vueApp.component('Card', Card);
  vueApp.component('Badge', Badge);
  vueApp.component('Chip', Chip);
  vueApp.component('Divider', Divider);
  vueApp.component('Sidebar', Sidebar);
  vueApp.component('Skeleton', Skeleton);
  vueApp.component('Avatar', Avatar);
  vueApp.component('Dialog', Dialog);
  vueApp.component('DataTable', DataTable);
  vueApp.component('InputText', InputText);
  vueApp.component('Dropdown', Dropdown);
  vueApp.component('RadioButton', RadioButton);
  vueApp.component('Checkbox', Checkbox);
  vueApp.component('Column', Column);
  vueApp.component('ColumnGroup', ColumnGroup);
  vueApp.component('Row', Row);
  vueApp.component('MultiSelect', MultiSelect);
  vueApp.component('AutoComplete', AutoComplete);
  vueApp.component('ProgressSpinner', ProgressSpinner);
  vueApp.component('Textarea', Textarea);
  vueApp.component('Calendar', Calendar);
  vueApp.component('TOPButton', TOPButton);
  vueApp.component('TOPBackButton', TOPBackButton);
  vueApp.component('TOPTable', TOPTable);
  vueApp.component('TOPDivider', TOPDivider);
  vueApp.component('TOPSearchInput', TOPSearchInput);
  vueApp.component('TOPCard', TOPCard);
  vueApp.component('TOPDialog', TOPDialog);
  vueApp.component('TOPAutocomplete', TOPAutocomplete);

  vueApp.component('TOPInputText', TOPInputText);
  vueApp.component('TOPSuccessIcon', TOPSuccessIcon);
  vueApp.component('TOPAttachedFilesList', TOPAttachedFilesList);
  vueApp.component('TOPDateTimeInput', TOPDateTimeInput);
  vueApp.component('TOPTableLink', TableLink);

  // New tevora components
  vueApp.component('TevoraRadioButton', TevoraRadioButton);
  vueApp.component('TevoraModalInputText', TevoraModalInputText);
  vueApp.component('TevoraButton', TevoraButton);
  vueApp.component('TevoraChip', TevoraChip);
  vueApp.component('TevoraTag', TevoraTag);
  vueApp.component('TevoraCheckbox', TevoraCheckbox);
  vueApp.component('TevoraSearchInput', TevoraSearchInput);
  vueApp.component('TevoraInputText', TevoraInputText);
  vueApp.component('TevoraDropdown', TevoraDropDown);
  vueApp.component('TevoraCollapsiblePanel', TevoraCollapsiblePanel);
  vueApp.component('TevoraTable', TevoraTable);
  vueApp.component('TevoraMenu', TevoraMenu);
  vueApp.component('TevoraIconSwitch', TevoraIconSwitch);
};

export default defineComponentsPlugin;
