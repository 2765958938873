import type { Router } from 'vue-router';

const App = () => import('./App.vue');

export default (router: Router) => {
  router.addRoute({
    path: '/projects',
    name: 'projects',
    meta: {
      title: 'Projects',
      requiresAuth: true,
      breadcrumbs: [{ to: 'dashboard', name: 'Home' }],
    },
    component: App,
  });
};
