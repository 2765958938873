import ErrorCodes from './error-codes';

export default {
  [ErrorCodes.CannotCreateFromTemplate]: 'Template format is invalid.',
  [ErrorCodes.CompanyNotFound]: 'Company not found.',
  [ErrorCodes.DuplicateFilename]:
    'The filename you are using has already been taken over by another file, please try again with another name.',
  [ErrorCodes.EmailTaken]: 'Email address already in use.',
  [ErrorCodes.FailedLogin]: 'Your credentials are not correct.',
  [ErrorCodes.InvalidGroups]:
    'You are not in the correct group to access this Application.',
  [ErrorCodes.InvalidUser]: 'We could not find any user with that email.',
  [ErrorCodes.NoCSVFile]:
    'Only CSV files are accepted. This may just require adding a .csv to the filename.',
  [ErrorCodes.NotAllowedToRenameFilesWithSameName]:
    'Cannot rename the file with the same name.',
  [ErrorCodes.NotSmsFactor]:
    'You do not have MFA activated, please contact your administrator.',
  [ErrorCodes.ProjectNotFound]: 'The poject was not found.',
  [ErrorCodes.ReassignmentoOfMultipleMainRolesNotAllowed]:
    "A lead role cannot be assigned to another user that already has a lead role. Please reassign the other user's role to a non-lead role first.",
  [ErrorCodes.TaskListRequiredFieldMissingTitle]:
    'Required field missing - Title.',
  [ErrorCodes.UserCanNotReAssignTask]: 'This task assignation is not allowed.',
  [ErrorCodes.UserCanNotUpdateProject]:
    'You are not allowed to update this project.',
  [ErrorCodes.UserHasNotTasksToBeReassigned]:
    'There are not tasks to be reassigned.',
  [ErrorCodes.UserHasPmOrLoadRole]:
    'Action not allowed because the user you are trying to select is already a Lead or PM.',
  [ErrorCodes.UserIsInactive]:
    'Is not allowed to add the user to the project because the user is inactive.',
  [ErrorCodes.TaskTrackerNotFound]:
    'No templates found for the chosen practice.',
  [ErrorCodes.TaskTrackerTaskNotFound]: 'No tasks found for chosen template.',
  [ErrorCodes.TaskTrackerInWrongStatus]: 'Invalid status.',
  [ErrorCodes.InvalidPractice]: 'Invalid practice.',
  [ErrorCodes.UserNotFound]: 'User not found.',
  [ErrorCodes.UserNotInProject]:
    'You do not have access to that project. Contact someone on the project team to get access.',
  [ErrorCodes.ProjectAlreadyExist]: 'Project name is already taken.',
  [ErrorCodes.NoTimezone]: 'Timezone expected.',
} as Record<ErrorCodes, string>;
