import type { NavigationGuardNext, RouteLocation } from 'vue-router';
import { useUserStore } from '@/stores/user.store';
import { useCookies } from '@vueuse/integrations/useCookies';
import { getUser } from '@/api/users.api';
import { getUserCompanies } from '@/api/company.api';
import { useCompaniesStore } from '@/stores/companies.store';
import { usePortalStore } from '@/stores/portal.store';
import { getSystemSettings } from '@/api/system-settings.api';
import { SystemSettingsKey } from '@/enums/system-settings.enum';

export const validateSession = async (
  to: RouteLocation,
  _from: RouteLocation,
  next: NavigationGuardNext,
) => {
  if (to.matched.some((route) => route.meta.requiresAuth)) {
    try {
      const userStore = useUserStore();
      const companiesStore = useCompaniesStore();
      const cookie = useCookies();

      // TODO: MOVE THIS INSIDE IF WHEN TOP IS LIVE FOR EVERYONE
      const { data: user, error } = await getUser();

      if (
        !cookie.get(import.meta.env.VITE_APP_API_SESSION_TOKEN_COOKIE_NAME) ||
        !userStore.isLoggedIn
      ) {
        if (user.value?.data) {
          userStore.setLogin(true);
          userStore.setUser(user.value.data);

          if (user.value.data.id) {
            const { data: companies } = await getUserCompanies({
              user: user.value.data.id,
            });

            if (companies.value?.data) {
              companiesStore.setCompanies(
                companies.value.data,
                user.value.data.isTevora,
              );
            }
          }
        } else {
          throw error;
        }
      }

      // TODO: REMOVE THIS IF AND LET ONLY THE ELSE WHEN TOP IS LIVE FOR EVERYONE
      if (
        import.meta.env.VITE_ONLY_TEVORA === 'true' &&
        !user.value?.data.isTevora &&
        to.path !== '/projects'
      ) {
        return window.location.replace(
          import.meta.env.VITE_PROJECTS_PATH || '/projects',
        );
      } else {
        const toURL = window.localStorage.getItem('toURL');

        if (toURL) {
          window.localStorage.removeItem('toURL');
          return next(toURL);
        }
      }

      return next();
    } catch (error) {
      return false;
    }
  }
  return next();
};

export const validateMaintenance = async (
  _to: RouteLocation,
  _from: RouteLocation,
  next: NavigationGuardNext,
) => {
  const portalStore = usePortalStore();
  const { maintenanceMessage, setPortalState: setMaintenanceMessage } =
    portalStore;

  if (!maintenanceMessage) {
    const { data } = await getSystemSettings({
      keys: [SystemSettingsKey.Banner],
    });
    const maintenanceMessage =
      data?.value?.data && data?.value?.data.length
        ? data?.value?.data[0]
        : null;

    if (maintenanceMessage) {
      setMaintenanceMessage({ ...portalStore.$state, maintenanceMessage });
    }
  }
  return next();
};
