import { defineStore } from 'pinia';
import type {
  Company,
  CompanyModulesAccess,
} from '@/interfaces/company.interface';
import { getUserAccessAndPermissions, getCompany } from '@/api/company.api';
import Cookies from 'universal-cookie';
import type {
  ModulesAccess,
  SettingsPermissions,
} from '@/interfaces/access-permissions.interface';
import { usePortalStore } from './portal.store';
import {
  SystemSettingsKey,
  SystemSettingsStatus,
} from '@/enums/system-settings.enum';

const cookies = new Cookies();

interface CompaniesState {
  currentCompany?: Company;
  userCompanies: Company[];
  userModulesAccess: ModulesAccess;
  settingsPermissions: SettingsPermissions;
}

export const initialCompanyState: CompaniesState = {
  currentCompany: undefined,
  userCompanies: [],
  userModulesAccess: {
    risks: null,
    projects: null,
    requests: null,
    reports: null,
    assets: null,
  },
  settingsPermissions: {
    users: {
      editHelp: false,
      editTevoraAdmins: false,
      editModulesAccess: false,
      editPractices: false,
    },
    company: {
      editInformation: false,
      editModulesAccess: false,
      editUsersAndTeams: false,
    },
  },
};

export const useCompaniesStore = defineStore('companies', {
  state: (): CompaniesState => initialCompanyState,
  getters: {
    getNameFirstLetter(state: CompaniesState): string {
      return state.currentCompany?.name?.charAt(0)?.toUpperCase() || '';
    },
    getNoCachedCompanyLogo(state: CompaniesState): string | undefined {
      return state.currentCompany?.logo
        ? `${state.currentCompany.logo}?${new Date().getTime()}`
        : undefined;
    },
  },
  actions: {
    setCompanies(companies: Company[], isTevora = false) {
      this.userCompanies = companies;
      if (!this.currentCompany) {
        const companyId = cookies.get(
          import.meta.env.VITE_CURRENT_COMPANY_COOKIE,
        );

        const currentCompany = companies?.find(
          (company) => company.id === companyId,
        );

        const [firstCompany] = companies;

        if (currentCompany) {
          this.setCurrentCompany(companyId);
        } else if (!isTevora && firstCompany) {
          this.setCurrentCompany(firstCompany.id);
        } else if (firstCompany) {
          this.getUserCurrentCompanyAccess(firstCompany.id);
        } else if (!isTevora && !firstCompany) {
          const portalStore = usePortalStore();
          portalStore.setPortalState({
            ...portalStore.$state,
            noDataAccessMessage: {
              key: SystemSettingsKey.Banner,
              status: SystemSettingsStatus.Active,
              value: `
                No data access detected. Contact your company Client Admin for access or <a href="" style="text-decoration:underline">our Portal Helpdesk</a> for support.<br/>
                If your company is a new Tevora client, please contact Sales at 833.292.1609.<br/>
                If you are experiencing a breach and require immediate assistance, please contact our Incident Response hotline at 877.200.4010.
              `,
            },
          });
        }
      }
    },
    // TODO: @Mario move this to userStore
    async getUserCurrentCompanyAccess(companyId: Company['id']) {
      // INFO: requiered cookie for validate modules access
      const { data: access } = await getUserAccessAndPermissions(companyId);

      if (access.value) {
        this.userModulesAccess = access.value.data.modulesAccess;
        this.settingsPermissions = access.value.data.settingsPermissions;
      }
    },

    async setCurrentCompany(companyId: Company['id']) {
      const { data: company } = await getCompany(companyId);
      if (company.value) {
        this.currentCompany = company.value.data;
        const cookieOptions = {
          path: '/',
          domain: import.meta.env.VITE_DOMAIN,
        };
        cookies.set(
          import.meta.env.VITE_CURRENT_COMPANY_COOKIE,
          companyId,
          cookieOptions,
        );
        this.getUserCurrentCompanyAccess(companyId);
      }
    },

    async updateCurrentCompany(
      companyData: Partial<Omit<Company, 'modulesAccess'>>,
    ) {
      if (this.currentCompany) {
        const relatedCompany = this.userCompanies.find(
          (company) => company.id === this.currentCompany?.id,
        );
        Object.assign(this.currentCompany, companyData);
        if (relatedCompany) Object.assign(relatedCompany, companyData);
      }
    },

    async updateCurrentCompanyModulesAccess(
      modulesAccessData: Partial<CompanyModulesAccess>,
    ) {
      if (this.currentCompany) {
        const relatedCompany = this.userCompanies.find(
          (company) => company.id === this.currentCompany?.id,
        );
        if (this.currentCompany.modulesAccess)
          Object.assign(this.currentCompany.modulesAccess, modulesAccessData);
        if (relatedCompany?.modulesAccess)
          Object.assign(relatedCompany.modulesAccess, modulesAccessData);
      }
    },

    async deleteCompanyFromUser(companyId: Company['id'], isTevora = false) {
      const companies = this.userCompanies.filter(
        (company) => company.id !== companyId,
      );
      this.setCompanies(companies, isTevora);
    },
  },
});
