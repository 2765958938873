import { createRouter, createWebHistory } from 'vue-router';
import { validateMaintenance, validateSession } from './hooks';

const routes = [
  {
    path: '/',
    name: 'dashboard',
    alias: '/dashboard',
    component: () => import('../modules/dashboard/views/DashboardView.vue'),
    meta: {
      requiresAuth: true,
      title: 'Home',
    },
  },
  {
    path: '/:catchAll(.*)',
    component: () => import('../components/404NotFound.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(validateSession);
router.beforeEach(validateMaintenance);

export default router;
