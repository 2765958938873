import { defineStore } from 'pinia';
import { read, save } from '@/utils/local-storage.utils';
import type { User, UserSettings } from '@/interfaces/user.interface';
import { TevoraUserAccessRole, type UserRoles } from '@/enums/user.enum';
import { capitalizeFirstLetter } from '@/utils';
import { useCompaniesStore } from './companies.store';
import { parseUserRole } from '@/utils';

interface UserState {
  user: User;
  isLoggedIn: boolean;
  settings: UserSettings;
}

export const initialUserState: UserState = {
  user: {
    id: '',
    firstName: '',
    lastName: '',
    title: '',
    email: '',
    login: '',
    avatar: '',
    status: '',
    primaryPhone: '',
    isTevora: false,
    admin: null,
    sendDailyEmails: false,
    metadata: {
      firstLogin: false,
      isFederated: false,
      isLead: false,
    },
  },
  isLoggedIn: false,
  settings: {
    detailedView: read('detailed-view') || false,
  },
};

export const useUserStore = defineStore('user', {
  state: (): UserState => initialUserState,
  getters: {
    getNameFirstLetter(state: UserState): string {
      return state.user?.firstName?.charAt(0)?.toUpperCase() || '';
    },
    getNoCachedUserAvatar(state: UserState): string | undefined {
      return state.user?.avatar
        ? `${state.user.avatar}?${new Date().getTime()}`
        : undefined;
    },
    userIsStaffAdmin(state: UserState): boolean | undefined {
      return state.user.isTevora && state.user.admin?.active;
    },
    isUserWithoutCompanies(): boolean {
      const companiesStore = useCompaniesStore();
      return !companiesStore.userCompanies.length;
    },
  },
  actions: {
    setUser(user: User) {
      this.user = user;
    },
    setUserProperties(newProperties: Partial<User>) {
      this.user = { ...this.user, ...newProperties };
    },
    setLogin(status: boolean) {
      this.isLoggedIn = status;
    },
    logout() {
      this.setUser(initialUserState.user);
      this.setLogin(initialUserState.isLoggedIn);
      window.location.href = `${import.meta.env.VITE_API_URL}/auth/saml/logout`;
    },
    toggleDetailedView() {
      save('detailed-view', !this.settings.detailedView);
      this.settings = {
        ...this.settings,
        detailedView: !this.settings.detailedView,
      };
    },
  },
});
