import axios from 'axios';
import type { AxiosInstance } from 'axios';
import setupResponseInterceptors from './interceptors/response.interceptor';

const axiosInstance: AxiosInstance = axios.create({
  baseURL: import.meta.env.VITE_API_URL,
  withCredentials: true,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Client: 'top',
  },
});

setupResponseInterceptors(axiosInstance);

export default axiosInstance;
