enum ErrorCodes {
  CannotCreateFromTemplate = 'user_can_not_create_tasks_based_on_template',
  DuplicateFilename = 'duplicate_filename',
  EmailTaken = 'email_already_taken',
  FailedLogin = 'failed_login',
  InvalidGroups = 'invalid_groups',
  InvalidUser = 'invalid_user',
  NotAllowedToRenameFilesWithSameName = 'not_allowed_to_rename_files_with_the_same_name',
  NotSmsFactor = 'not_sms_factor',
  ProjectNotFound = 'project_not_found',
  ReassignmentoOfMultipleMainRolesNotAllowed = 'reassignment_of_multiple_main_roles_not_allowed',
  UserCanNotReAssignTask = 'user_can_not_re_assign_task',
  UserHasNotTasksToBeReassigned = 'user_has_not_tasks_to_be_re_asssigned',
  UserHasPmOrLoadRole = 'user_has_pm_or_lead_role',
  UserIsInactive = 'user_is_inactive',
  NoCSVFile = 'no_csv_file',
  UserNotFederatedOkta = 'Okta HTTP 403 E0000023 Operation failed because user profile is mastered under another system. ',
  DisplayUserNotFederatedOkta = "Operation failed because this user profile is controlled by another system. Please adjust your user profile using your company's SSO system. Profile information this applies to includes user's name and e-mail.",
  TaskListRequiredFieldMissingTitle = 'task_list_required_field_missing_title',
  TaskTrackerNotFound = 'task_tracker_not_found',
  TaskTrackerInWrongStatus = 'task_tracker_in_wrong_status',
  TaskTrackerTaskNotFound = 'task_tracker_task_not_found',
  InvalidPractice = 'invalid_practice',
  UserNotFound = 'user_not_found',
  UserCanNotUpdateProject = 'user_can_not_update_project',
  CompanyNotFound = 'company_not_found',
  UserNotInProject = 'user_not_in_project',
  ProjectAlreadyExist = 'project_already_exist',
  MalFormedTaskTrackerFile = 'mal_formed_task_tracker_file',
  NoTimezone = 'no_timezone',
}
export default ErrorCodes;
