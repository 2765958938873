import axiosInstance from './api.instance';
import { useAxios } from '@vueuse/integrations/useAxios';
import type { SystemSetting } from '@/interfaces/system-settings.interface';
import { serializeQueryString } from '@/utils';
import type { AxiosResponse } from 'axios';

export const updateSystemSetting = (
  id: string,
  setting: Partial<SystemSetting>,
) => {
  return useAxios(
    `/system-settings/${id}`,
    { method: 'PUT', data: setting },
    axiosInstance,
  );
};

export const getSystemSettings = (query: { keys: string[] }) =>
  useAxios<AxiosResponse<SystemSetting[]>>(
    `/system-settings?${serializeQueryString(query)}`,
    { method: 'GET' },
    axiosInstance,
  );
