<!-- eslint-disable @typescript-eslint/no-explicit-any -->
<script setup lang="ts">
import { computed } from 'vue';
import AutoComplete from 'primevue/autocomplete';

interface Props {
  modelValue?: any;
  label?: string;
  delay: number;
  field?: string | ((data: any) => string) | undefined;
  name: string;
  suggestions: any[];
  vuelidate?: any;
}

const props = defineProps<Props>();
const emit = defineEmits(['update:modelValue', 'complete']);

const v$ = computed(
  () => (props.vuelidate && props.vuelidate[props.name || '']) || {},
);

const model = computed({
  get() {
    return props.modelValue || '';
  },
  set(value: any) {
    emit('update:modelValue', value || '');
  },
});
</script>

<template>
  <div class="field tevora-autocomplete">
    <div v-if="label" class="tevora-autocomplete__title">{{ label }}</div>
    <AutoComplete
      v-model="model"
      :delay="delay"
      :field="field"
      auto-highlight
      dropdown
      input-class="tevora-autocomplete__input"
      :suggestions="suggestions"
      @complete="$emit('complete', $event)"
    />
    <small v-if="v$.$error" class="text-pink-500">
      {{ v$.$errors[0].$message }}
    </small>
  </div>
</template>

<style lang="scss">
.tevora-autocomplete {
  display: flex;
  flex-direction: column;
  &__title {
    margin-bottom: 4px;
    font-weight: bold;
    font-size: 0.9rem;
    padding-left: 0;
  }
}
</style>
