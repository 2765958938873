<script setup lang="ts">
import { computed } from 'vue';

interface Props {
  label: string;
  modelValue: string | undefined | null | Date;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  vuelidate?: any;
  name?: string;
  readonly?: boolean;
  required?: boolean;
  type?: 'datetime' | 'date' | 'time';
  minDate?: Date | undefined;
  maxDate?: Date | undefined;
  placeholder?: string | undefined;
}

const props = withDefaults(defineProps<Props>(), {
  label: '',
  vuelidate: undefined,
  name: '',
  readonly: false,
  required: false,
  type: 'date',
  minDate: undefined,
  maxDate: undefined,
  placeholder: 'Pick a Date',
});
const emit = defineEmits(['update:modelValue', 'date-select']);

const v$ = computed(
  () => (props.vuelidate && props.vuelidate[props.name || '']) || {},
);

const typeProps = computed(() => {
  switch (props.type) {
    case 'datetime':
      return {
        format: 'M dd, yy',
        showTime: true,
        timeOnly: false,
        icon: 'calendar',
      };
    case 'date':
      return {
        format: 'M dd, yy',
        showTime: false,
        timeOnly: false,
        icon: 'calendar',
      };
    default:
      return { showTime: true, timeOnly: true, icon: 'clock' };
  }
});

const editableContent = computed({
  get() {
    return props.modelValue;
  },
  set(value: any) {
    emit('update:modelValue', value);
  },
});
</script>

<template>
  <div class="field top-calendar">
    <div class="field__title">
      {{ label }}
      <span v-if="required">*</span>
    </div>
    <div class="field__icon-wrapper">
      <FontIcon class="icon" :icon="typeProps.icon" size="lg" />
    </div>
    <Calendar
      v-model="editableContent"
      class="field__calendar"
      :date-format="typeProps.format"
      hour-format="12"
      :placeholder="placeholder"
      :min-date="minDate ? minDate : undefined"
      :max-date="maxDate ? maxDate : undefined"
      touch-ui
      :time-only="typeProps.timeOnly"
      :show-time="typeProps.showTime"
      @date-select="emit('date-select')"
    />
    <div
      v-if="typeProps.showTime && editableContent"
      class="field__timezone-wrapper"
    >
      <span>PST</span>
    </div>
    <small v-if="v$.$error" class="text-pink-500">
      {{ v$.$errors[0].$message }}
    </small>
  </div>
</template>

<style scoped lang="scss">
.field {
  white-space: nowrap;
  display: flex;
  flex-direction: column;
  margin-right: 0.5rem !important;
  &__input {
    input {
      border-radius: 12px;
      width: 100%;
      padding: 0.8rem 1.4rem;
      padding-left: 3rem;
    }
  }
  &__title {
    font-weight: bold;
    font-size: 0.9rem;
    padding-left: 0;
    margin-bottom: 4px;
  }
  &__icon-wrapper {
    position: absolute;
    .icon {
      position: absolute;
      top: 38px;
      left: 20px;
      color: var(--color-primary-gunmetal);
    }
  }
  &__timezone-wrapper {
    position: absolute;
    span {
      position: absolute;
      left: 7.6rem;
      top: 36px;
      font-weight: 500;
      color: var(--color-primary-gunmetal);
    }
  }
}
</style>
