import { defineStore } from 'pinia';
import type {
  Notification,
  NotificationsResponse,
} from '@/interfaces/notification.interface';

interface NotificationState {
  notifications: Notification[];
  unreads: number;
}

export const initialNotificationsState: NotificationState = {
  notifications: [],
  unreads: 0,
};

export const useNotificationsStore = defineStore('notifications', {
  state: (): NotificationState => initialNotificationsState,
  actions: {
    setNotifications(notifications: NotificationsResponse) {
      this.notifications = notifications.notifications;
      this.unreads = notifications.unreads;
    },
    showToastNotification(options: {
      detail?: string;
      summary: string;
      severity: string;
      life?: number;
    }) {
      if (!options.life) options.life = 5000;
      this.toast({ ...options, group: 'notifications' });
    },
  },
});
