import type { Router } from 'vue-router';

const App = () => import('./App.vue');

const settingsBreadcrumbs = [
  { to: 'dashboard', name: 'Home' },
  { to: 'settings.home.personal', name: 'Settings' },
];

export default (router: Router) => {
  router.addRoute({
    path: '/settings',
    name: 'settings',
    meta: {
      title: 'Settings',
      requiresAuth: true,
      breadcrumbs: [{ to: 'dashboard', name: 'Home' }],
    },
    component: App,
  });

  router.addRoute('settings', {
    path: '',
    name: 'settings.home',
    meta: {
      title: 'Settings',
      breadcrumbs: [{ to: 'dashboard', name: 'Home' }],
    },
    component: () => import('./views/SettingsView.vue'),
  });

  router.addRoute('settings.home', {
    path: '',
    alias: 'personal',
    name: 'settings.home.personal',
    component: () => import('./views/PersonalView.vue'),
  });

  router.addRoute('settings.home', {
    path: 'company',
    name: 'settings.home.company',
    meta: {
      title: 'Company',
      breadcrumbs: settingsBreadcrumbs,
    },
    component: () => import('./views/CompanyView.vue'),
  });

  router.addRoute('settings.home', {
    path: 'company/user/:userId',
    name: 'settings.home.company.user',
    meta: {
      title: 'Company User',
      breadcrumbs: [
        ...settingsBreadcrumbs,
        {
          to: 'settings.home.company',
          name: 'Company',
        },
      ],
    },
    component: () => import('./views/company/CompanyUserDetails.vue'),
  });

  router.addRoute('settings.home', {
    path: 'teams',
    name: 'settings.home.teams',
    meta: {
      title: 'Teams',
      breadcrumbs: settingsBreadcrumbs,
    },
    component: () => import('./views/TeamsView.vue'),
  });

  router.addRoute('settings.home', {
    path: 'tevora',
    name: 'settings.home.tevora',
    meta: {
      title: 'Tevora',
      breadcrumbs: settingsBreadcrumbs,
    },
    component: () => import('./views/StaffView.vue'),
  });

  router.addRoute('settings.home', {
    path: 'tevora/user/:userId',
    name: 'settings.home.tevora.user',
    meta: {
      title: "User's Practices",
      breadcrumbs: [
        ...settingsBreadcrumbs,
        {
          to: 'settings.home.tevora',
          name: 'Tevora',
        },
      ],
    },
    component: () => import('./views/staff/StaffUserDetails.vue'),
  });

  router.addRoute('settings.home', {
    path: 'practices',
    alias: 'templates',
    name: 'settings.home.practices',
    meta: {
      title: 'Practices & Templates',
      breadcrumbs: settingsBreadcrumbs,
    },
    component: () => import('./views/PracticesAndTemplatesView.vue'),
  });

  router.addRoute('settings.home', {
    path: 'practices/users/:practiceId',
    name: 'settings.home.practices.users',
    meta: {
      title: 'Practice User',
      breadcrumbs: [
        ...settingsBreadcrumbs,
        {
          to: 'settings.home.practices',
          name: 'Practices & Templates',
        },
      ],
    },
    component: () => import('./views/practices/PracticeStaff.vue'),
  });

  router.addRoute('settings.home', {
    path: 'practices/templates/:practiceId',
    name: 'settings.home.practices.templates',
    meta: {
      title: 'Practice Templates',
      breadcrumbs: [
        ...settingsBreadcrumbs,
        {
          to: 'settings.home.practices',
          name: 'Practices & Templates',
        },
      ],
    },
    component: () => import('./views/practices/PracticeTemplates.vue'),
  });

  router.addRoute('settings.home', {
    path: 'system',
    name: 'settings.home.system',
    meta: {
      title: 'System Admin',
      breadcrumbs: settingsBreadcrumbs,
    },
    component: () => import('./views/SystemView.vue'),
  });
};
